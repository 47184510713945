import React from "react";

function LocationOverview() {
  return (
    <div>
      <div className="text-center mb-2">
        <h2 className="text-dark mb-2 visible">Standortübersicht</h2>
        <p>
          Sie finden mich gegenwärting an folgenden Standorten.
          <br /> Wo Ihre Therapie stattfindet, besprechen wir gemeinsam.
        </p>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-lg-6 px-md-8">
          <LocationMap
            title="Moorbek Rondell Norderstedt"
            // image="/images/map-nor-rathausallee.png"
            address="Rathausallee 70, 22846 Norderstedt"
            addressExtra="in der logopädischen Praxis von Jennifer Gasch"
            googleMapsLink="https://goo.gl/maps/By7dZGFEva447QLF9"
          >
            Direkt an dem Busbahnhof und der Endhaltestelle der U1 (Richtung Hamburg) und der AKN (Richtung Henstedt-Ulzburg) sind die Praxisräume zentral gelegen und gut
            erreichbar. Diese Räumlichkeiten sind Behindertengerecht ausgestattet. Parkmöglichkeiten in der Tiefgarage sind vorhanden.
          </LocationMap>
        </div>

        <div className="col-12 col-lg-6 mt-2 mt-lg-0 px-md-8">
          <LocationMap
            title="Hamburg Langenhorn"
            // image="/images/map-hh-tangstedter-landstraße.png"
            address="Tangstedter Landstraße 236, 22417 Hamburg"
            addressExtra="in der logopädischen Praxis von Nadine Holtz"
            googleMapsLink="https://goo.gl/maps/SFnnGrAYGWyYEzBh6"
          >
            Gut an den öffentlichen Nahverkehr angeschlossen, die U1 Langenhorn Nord ist ca. 5 Min Fußweg entfernt. Diese Räumlichkeiten sind nicht behindertengerecht.
            Parkmöglichkeiten sind an der Straße vorhanden.
          </LocationMap>
        </div>
      </div>
    </div>
  );
}

const LocationMap = (props) => {
  return (
    <figure>
      <figcaption className="text-center">
        <h2>{props.title}</h2>

        {/* <img alt={"Karte " + props.address} className="img-fluid mt-1" src={props.image} /> */}

        <p className="mt-2">
          <strong>{props.address}</strong>
          <br />
          <small>{props.addressExtra}</small>
        </p>

        <a className="button px-2" href={props.googleMapsLink} target={"_blank"}>
          Routenplanung auf Google Maps
        </a>

        <p className="mt-2 text-justify">{props.children}</p>
      </figcaption>
    </figure>
  );
};

export default LocationOverview;
